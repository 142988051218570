#loginLogo {
  display: block;
  width: 65%;
  height: auto;
  margin-left: 17.5%;
  margin-top: 8%;
}

#loginDivOuter {
  overflow-x: clip;
  background-color: #b4e0fa;
  width: 50px;
  height: 350px;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
}

#loginDivInner {
  display: block;
  position: absolute;
  top: 20%;
  right: 35%;
  width: 30%;
  height: 50%;
  border: double;
  background-color: white;
}

#loginDivInner .mdc-typography--caption,
#loginDivInner .mdc-form-field,
#loginDivInner .mdc-text-field {
  width: 90% !important;
  margin-left: 5%;
}

#loginDivInner .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  text-align: center;
}

#loginDivInner .mdc-button {
  width: 89.45% !important;
  margin-left: 6%;
  margin-top: 7%;
  position: absolute;
  top: 40%;
}

#loginDivInner .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  color: var(--mdc-theme-primary);
}

#invalidCred {
  color: var(--mdc-theme-on-error);
}

#tsalta {
  color: white;
  position: absolute;
  top: 5%;
  right: 46%;
}

#tsaltaLogo {
  top: 3%;
  width: 40%;
  position: absolute;
  left: 30%;
}

#test {
  background-color: dodgerblue;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #b4e0fa inset !important;
}