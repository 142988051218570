:root {
  --mdc-theme-primary: #0c3897;
  --mdc-theme-primary-bright: #35c2ff;
  --mdc-theme-primary-dark: #103e52;
  --mdc-theme-secondary: var(--mdc-theme-primary);
  --secondary-theme-color-bright: var(--mdc-theme-primary-bright);
  --secondary-theme-color-dark: var(--mdc-theme-primary-dark);
  --tertiary-theme-color: var(--mdc-theme-primary);
  --tertiary-theme-color-dark: var(--mdc-theme-primary-dark);
  --mdc-theme-on-error: #ff9a28;
  --primary-surface: rgb(46, 46, 46);
  --page-backgrounds: rgb(26, 26, 26);
  --assertion-color: #00b0eb;
  --assertion-color-dark: #00a4db;
  --prediction-color: #ffd700;
  --drawer-selected-color: #00b0eb3a;
  --dif-grids-dark: #112233;
  --dif-grids-light: #1d364f;
  --graph-sidebar-section-dividers: #444444;
  --hyperlink-color: #2ca2d4;
  --root-node-highlight: var(--mdc-theme-primary-bright);
  --mdc-theme-highlight-listitem: #267060;

  /* dialog and search menu background color */
  --mdc-theme-background: rgb(255, 255, 255);
  /* dashboard card background */
  --mdc-theme-surface: #ffffff;
  /* primary button text */
  --mdc-theme-on-primary: rgb(214, 214, 214);
  /* secondary button text */
  --mdc-theme-on-secondary: var(--mdc-theme-on-primary);
  /* other text on dark */
  --mdc-theme-on-surface: var(--mdc-theme-on-primary);
  /* disabled inputs and input line hovers */
  --mdc-theme-on-surface-dark: rgb(134, 134, 134);
  /* drawer button text */
  --mdc-theme-text-primary-on-background: var(--mdc-theme-on-surface);
  /* card header text */
  --mdc-theme-text-secondary-on-background: var(--mdc-theme-on-primary);
  /* drawer arrows */
  --mdc-theme-text-hint-on-background: var(--mdc-theme-on-surface);
  /* dialog::not background, scrollbar underline */
  --mdc-theme-text-primary-on-light: var(--mdc-theme-on-primary);
  /* slider popup text */
  --mdc-theme-text-primary-on-dark: var(--mdc-theme-on-surface);

  --top-app-bar-height: 60px;

  /* unused rmwc global css theme variables */
  --mdc-theme-text-secondary-on-dark: blue;
  --mdc-theme-text-hint-on-dark: blue;
  --mdc-theme-text-disabled-on-dark: blue;
  --mdc-theme-text-icon-on-dark: blue;
  --mdc-theme-text-disabled-on-background: var(--mdc-theme-on-primary);
  --mdc-theme-text-icon-on-background: blue;
  --mdc-theme-text-secondary-on-light: rgb(0, 255, 170);
  --mdc-theme-text-hint-on-light: rgb(0, 255, 170);
  --mdc-theme-text-disabled-on-light: rgb(0, 255, 170);
  --mdc-theme-text-icon-on-light: rgb(0, 255, 170);
}
body {
  margin: 0;
}
