#groupHead {
    position: absolute;
    top: 11%;
    width: 100%;
    color: dodgerblue;
}

#addGroup {
    width: 100%;
    height: 100%;
}

.mdc-dialog .mdc-dialog__surface {
    width: 1000px;
    height: 650px;
}

#rightGrid {
    position: relative;
    left: 40%;
    top: 0%;
}

#select-multiple-native {
    height: 350px;
}

#leftGrid {
    top: 25%;
    position: absolute;
    right: 77%;

}

#rightGrid {
    top: 25%;
    position: absolute;
    right: 28%;
}

#buttonDiv {
    position: absolute;
    right: 23%;
    width: 30%;
    top: 50%;
}

#paper {
    width: 400px;
    height: 350px;
    overflow: auto;
}

#rightPaper {
    width: 400px;
    height: 350px;
    position: absolute;
    left: 50%;
    overflow: auto;
}

#addButton {
    top: 92%;
    position: absolute;
    right: 3%;
    background-color: dodgerblue;
    color: white;
    height: 40px;
    width: 175px;
}