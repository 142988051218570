#reportDateSelect {
  flex-wrap: wrap;
  display: flex;
}

#searchStartDate {
  padding-left: 10px;
  right: -220px;
  position: absolute;
  top: -25px;
}

#toolsHead {
  position: absolute;
  top: 75px;
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;
}

#toolList {
  height: 70%;
  width: 90%;
  left: 0;
  position: absolute;
  top: 10%;
  color: black;
}

#printDialogContent {
  width: 1200px;
  height: 500px;
}

#labReqDialogContent {
  width: 1200px;
  height: 500px;
}

#quarterlyHead {
  color: dodgerblue;
}

#selections {
  position: relative;
  left: 2%;
  top: 28%;
  color: black;
}

#resTestLabel {
  margin-left: 0px;
  width: 250px;
  top: 50px
}

#close {
  width: 10px;
  right: -1100px;
}

#quarterlyDialogContent {
  width: 1200px;
  height: 500px;
}

#quarterlyContent {
  position: absolute;
  top: 450px;
  flex-wrap: wrap;
  display: flex;
}

#quarterlySelect {
  width: 400px;
}

#quarterlySelectButton {
  top: 10px;
  left: 835px;
  position: absolute;
  width: 1000px;
}

#printIconBoarder {
  position: relative;
  left: 10%;
  top: 22%;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#facesheetSelectButton {
  width: 50%;
  position: absolute;
  right: 55%;
  top: 12%;
}

#facesheetUpdate {
  position: absolute;
  width: 100%;
  top: 55%;
  left: 2%;
}

#facesheetContent {
  position: absolute;
  width: 100%;
  top: 28%;
  left: 2%;
}

#facesheetUpdateSelectButton {
  width: 50%;
  position: absolute;
  right: 55%;
  top: 12%;
}

#genFacesheet {
  left: 40%;
  top: 10px;
}

#facesheetTitle {
  position: absolute;
  top: 20%;
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;

}

#updateFacesheetTitle {
  position: absolute;
  top: -80%;
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;

}

.mdc-dialog .mdc-dialog__surface {
  overflow-x: hidden;
}



#reportTypeRow {
  position: absolute;
  top: 20%;
}

#reportDateRow {
  position: absolute;
  top: 50%;
}
#reportCriticalRow {
  position: absolute;
  top: 77%;
}

#reportDateSelectRow {
  position: absolute;
  top: 62%;
}

#reportButtonRow {
  position: absolute;
  top: 85%;
  width: 100%;
}

#createReportsButton {
  position: absolute;
  left: 38%;
  background-color: dodgerblue;
  color: white;
  height: 50px;
  width: 200px;
}

#patientNameRow {
  position: absolute;
  top: 35%;
}

#facilitySelectRow {
  position: absolute;
  top: 35%;

}

#reportLoadBar {
  position: absolute;
  top: 83%
}

#testTypeRow {
  position: absolute;
  top: 29%;
}

#patientNameLabReqRow {
  position: absolute;
  top: 75%;
}

#testRow {
  position: absolute;
  top: 43%;
  left: 2.5%;
}

#requestTypeRow {
  position: absolute;
  top: 62%;
  left: 2.5%;
}

#facilityLabReqRow {
  position: absolute;
  top: 75%;
}

#uwRequestTypeRow {
  position: absolute;
  top: 43%;
  left: 2.5%
}

#uwFacilityLabReqRow {
  position: absolute;
  top: 57%;
}

#uwPatientNameLabReqRow {
  position: absolute;
  top: 57%;
}

#reportTitle {
  border-bottom: groove;
  border-color: dodgerblue;
  background-color: dodgerblue;
  color: white;
  text-align: center;
}

#labNameRow {
  position: absolute;
  top: 15%;
  left: 2.5%
}

#requestButton {
  background-color: dodgerblue;
  color: white;
  height: 200px;
  width: 300px;
  left: 15%;
  position: absolute
}

#historyButton {
  background-color: dodgerblue;
  color: white;
  height: 200px;
  width: 300px;
  right: 15%;
  position: absolute
}