#welcome {
  font-size: 17px;
  padding-right: inherit;
}

#searchComponent {
  width: 40%;
  margin-bottom: 8px;
  padding: 8px 12px;
}

#menuBar {
  width: 15%;
}

#logoutBar {
  width: 25%;
}

.mdc-top-app-bar {
  z-index: 7;
  position: absolute;
  top: 0;
  left: 0;
  height: 8%;
}

#top_app_bar_logo {
  padding-left: 0%;
  padding-top: 10%;
  display: block;
  width: 87px;
  height: 10%;
}


.mdc-top-app-bar__row {
  height: var(--top-app-bar-height);
}