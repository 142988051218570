#userHead{
    position: absolute;
    top:11%;
    width: 100%;
    color:dodgerblue;
}
#users{
    padding: 20px;
}
#lPaper {
    width: 400px;
    height: 350px;
    overflow: auto;
}
  #rPaper {
    width: 400px;
    height: 350px;
    overflow: auto;
  }
#selectorButtons{
    position: relative;
    left: 60%;
    top: 40%;
}
#groupSelector{
    position:relative;
    left:-5%;
}
#groupSelect{
    width:175px;
}
#addButton{
    top:92%;
    position:absolute;
    right: 3%;
    background-color:dodgerblue;
    color:white;
    height: 40px;
    width: 175px;
}
#addTitle{
    border-bottom: groove;
    border-color: dodgerblue;
    background-color: dodgerblue;
    color: white;
    text-align: center;
}