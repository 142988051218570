#noTrends {
  position: absolute;
  left: 45%
}

#trendHead {
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;
  padding-left: 40%;
}

#testList {
  height: '215px';
  position: 'reletive';
  overflow: 'auto';
}

#trendsDialogContent {
  width: 960px;
  height: 650px;
}