#labReqTitle{
    position: absolute;
    left: 40%;
    color: dodgerblue;
}
#labNameRow{
    position:absolute;
    top:10%;
    left: 0%;
    width:100%
  }
  #testTypeRow{
    position: absolute;
    top:20%;
    left: 28.2%;
  }
  #createReportButton{
    position:absolute;
    left:45%;
    background-color:dodgerblue;
    color:white;
    height: 50px;
    width: 200px;
  }
  #uwRequestTypeRow{
    position:absolute;
    top:25%;
    left: 0%;
    width: 100%;
  }
  #patientNameLabReqRow{
    position:absolute;
    top:50%;
    left:28%
  }
  #uwPatientNameLabReqRow{
    position:absolute;
    top:40%;
    left:28%
  }
  #paper{
    position:absolute;
    left: 40%;
    top: 40%
  }
  #paper2{
    position:absolute;
    left: 25%;
    top: 50%
  }
  #paper3{
    position:absolute;
    left: 43%;
    top: 50%;
  }
  #paper4{
    position:absolute;
    left: 60%;
    top: 50%
  }
  #paper5{
    position:absolute;
    left: 80%;
    top: 50%
  }
 #testGrid{
   width: 100%;
   position: absolute;
   top: 40%;
 }
 #uwFacilityLabReqRow{
  position:absolute;
  top:25%;
  left: 0%;
  width: 100%;
}
#medsList{
  position:absolute;
  left: 30%;
  top: 40%;
  width: 20%;
  height: 40.5%;
}
#patientPaper{
  position:absolute;
  left: 55%;
  top: 40%;
  width: 20%;
  height: 40.5%;
}
#covList{
  position:absolute;
  left: 13%;
  top: 40%;
  width: 37%;
  height: 40.5%;
}
#bloodPaper{
  position:absolute;
  left: 40%;
  top: 40%;
  width: 20%;
  height: 40.5%;
}
#reqPaper{
  position:absolute;
  left: 40%;
  top: 40%;
  width: 400px;
  height: 40.5%;
  /* overflow:auto; */
}


