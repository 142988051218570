.collapse {
  display: none;
}

.collapse.in {
  display: block;
  overflow: scroll;
}

#metricsDialogContent {
  width: 960px;
  height: 500px;
}

#createMetricsButton {
  top: 20px;
  position: relative;
  background-color: dodgerblue;
  color: white;
  height: 50px;
  width: 200px;
}

#topRow {
  position: absolute;
  top: 85%;
  width: 100%;
}

#nameRow {
  position: absolute;
  top: 30%;
}

#typeRow{
  position: absolute;
  top: 20%;
}
