#OHTable {
    height: 70%;
    width: 100%;
    left: 0;
    position: absolute;
    top: 150px
}

#ohHead {
    font-family: system-ui;
    font-size: x-large;
    color: dodgerblue;
    top: 90px;
    position: absolute;
}
#clearSearch{
    right: 10%;
    top: 10%;
    position: absolute
}