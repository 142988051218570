#resultTable {
  height: 70%;
  width: 100%;
  position: absolute;
  top: 150px;
}

#fhTable {
  height: 70%;
  width: 98%;
  position: absolute;
  top: 150px;
}

#resultPag1 {
  position: absolute;
  bottom: 0;
}

#advancedSearch {
  padding: 10px;
}

#labHead {
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;
  width: 100%;
}

#labHead1 {
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;
  width: 100%;
  top: '100px';
  position: 'absolute';
}

#resultDialog {
  width: 1000px;
  height: 900px;
}

.mdc-dialog .mdc-dialog__surface {
  max-width: 2000px;
}

#cellBold {
  font-weight: bolder;
}

#noResult {
  position: absolute;
  right: 50%;
}

#noOBR {
  position: absolute;
  right: 50%;
}

#noOBX {
  position: absolute;
  right: 50%;
}

#printLoad {
  position: absolute;
  bottom: 6%;
  right: 4%;
}

#patientDmvDialog {
  left: 22%;
  width: 1000px;
  height: 900px;
}

#printDialogButton {
  top: 92%;
  position: absolute;
  right: 3%;
  background-color: dodgerblue;
  color: white;
  height: 40px;
  width: 175px;
}

#fileButton {
  left: 125px;
}

#createReportButton {
  position: absolute;
  left: 38%;
  background-color: dodgerblue;
  color: white;
  height: 50px;
  width: 200px;
  top: '75%'
}

#searching {
  width: 100%
}

#searchLoadBar {
  position: absolute;
  top: 20%;
  right: 52%;
}