#dashboardIconDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#dashboardIconBoarder {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
}
#buttons{
  position: fixed;
  top: 15%;
  width: 100%;
  height:100%;
}
