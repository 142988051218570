#facilityLoginTable{
    height: 70%;
    width: 100%;
    position: absolute;
    top: 20%;
}
#addButton{
    top:92%;
    position:absolute;
    right: 3%;
    background-color:dodgerblue;
    color:white;
    height: 40px;
    width: 175px;
  }
  #addButton:disabled{
    top:92%;
    position:absolute;
    right: 3%;
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    height: 40px;
    width: 175px;
  }
  #addTitle{
    border-bottom: groove;
    border-color: dodgerblue;
    background-color: dodgerblue;
    color: white;
    text-align: center;
  }
