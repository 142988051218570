#patientTable {
  height: 70%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 150px;
}

#fileButton {
  left: 125px;
}

#patientPag {
  position: absolute;
  bottom: 0;
}

#patientSearchCol {
  width: 100%;
}

#patientHead {
  font-family: system-ui;
  font-size: x-large;
  color: dodgerblue;
}

#noResult {
  position: absolute;
  right: 50%;
}

hr.solid {
  border-top: 3px solid #bbb;
}

#patientInfoDialog {
  left: 22%;
  width: 1000px;
  height: 900px;
}

#labNamePatientRow {
  position: absolute;
  top: 15%;
  left: 8%;
  width: 75%
}

#typePatientRow {
  position: absolute;
  top: 30%;
  left: 8%;
  width: 75%
}

#patientLabs {
  position: absolute;
  left: 30%;
  top: 43%;
  width: 40%;
  height: 40.5%;
  /* overflow:auto; */
}

#patReportButton {
  position: absolute;
  left: 38%;
  background-color: dodgerblue;
  color: white;
  height: 50px;
  width: 200px;

}

#generalPaper {
  position: absolute;
  left: 33%;
  top: 42%;
  width: 35%;
  height: 40.5%;
  /* overflow:auto; */
}

#addButton:disabled {
  top: 92%;
  position: absolute;
  right: 3%;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
#addButton {
  top: 92%;
  position: absolute;
  right: 3%;
  background-color: dodgerblue;
  color: white;
  height: 40px;
  width: 175px;
}