.mdc-drawer {
  height: 100%;
}

.content {
  position: absolute;
  height: calc(100% - 85px) !important;
  width: 100% !important;
  top: 85px;
  right: 0;
  transition-property: left, right;
  transition: 0.3s ease-out;
  overflow: hidden;
}

.mdc-list-item__graphic {
  margin-right: calc(1vw);
}

#drawerLevel1 {
  margin-left: 30px;
  margin-right: 8px;
}

#drawerLevel2 {
  margin-left: 60px;
  margin-right: 8px;
}

#drawerDiv {
  width: 100%;
  position: absolute;
  left: 0;
  height: 100vh;
}